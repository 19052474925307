/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var map = new GMaps({
          div: '#map',

          lat: 51.369975,
          lng: 5.221266,
          zoom: 13,
          scrollwheel: false,
          zoomControl : true,
          zoomControlOpt: {
            style : "SMALL",
            position: "TOP_LEFT"
          },
          panControl : true,
          streetViewControl : false,
          mapTypeControl: false,
          overviewMapControl: false
        });

        var styles = [
          {
            stylers: [
              { hue: "#ff0000s" },
              { saturation: 0 }
            ]
          }, {
            featureType: "road",
            elementType: "geometry",
            stylers: [
              { lightness: 100 },
              { visibility: "simplified" }
            ]
          }, {
            featureType: "road",
            elementType: "labels",
            stylers: [
              { visibility: "off" }
            ]
          }
        ];

        map.addStyle({
          styledMapName:"Styled Map",
          styles: styles,
          mapTypeId: "map_style"
        });

        map.setStyle("map_style");

        map.addMarker({lat: 51.369975, lng: 5.221266, title: 'Oefentherapie Cesar de Cirkel', infoWindow: {maxwidth:300, content: '<div class="txtInfoWindow"><b>Oefentherapie Cesar de Cirkel</b><br>Hofdreef 1<br>5531 EW Bladel<br>Noord-Brabant<br>Kempen<br><br>t: 497381871</div>'}});

        jQuery('.mobile-menu').click(function(){
          if( jQuery(this).hasClass('open') ){
            jQuery('nav').removeClass('active');
            jQuery(this).removeClass('open');
          }else{
            jQuery('nav').addClass('active');
            jQuery(this).addClass('open');
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        jQuery('.image-carousel').owlCarousel({
          autoplay:true,
          loop:true,
          margin:10,
          nav:true,
          dots:false,
          items:1,
          thumbs:true,
          thumbsPrerendered: true
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
